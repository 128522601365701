<template>
  <div class="space-y-6">
    <div
      class="bg-white px-6 py-5 text-left shadow sm:rounded-lg sm:p-6 lg:p-12"
      v-loading="profileLoading"
    >
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <h3 class="text-lg font-medium leading-6 text-gray-900">Profile</h3>
          <p class="mt-1 text-sm text-gray-500">
            This information will be displayed publicly so be careful what you
            share.
          </p>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
          <form class="space-y-6" action="#" method="POST">
            <div>
              <label class="block text-sm font-medium text-gray-700">
                Photo
              </label>
              <div class="mt-1 flex items-center space-x-5">
                <span
                  class="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100"
                >
                  <img
                    v-if="user !== null && user.Picture !== null"
                    class="h-12 w-12 rounded-full"
                    :src="`data:image/png;base64, ${user.Picture}`"
                    alt=""
                  />
                  <svg
                    v-else
                    class="h-full w-full text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">
                Change
              </label>
              <el-upload
                class=""
                drag
                accept="image/jpeg,image/gif,image/png"
                action=""
                :on-change="onUploadChange"
                :auto-upload="false"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
                :limit="1"
                :file-list="fileList"
              >
                <svg
                  class="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="el-upload__text">
                  <em>Upload a file</em> or drag and drop
                </div>
              </el-upload>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="flex justify-end">
      <button
        type="button"
        class="focus:outline-none rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500"
      >
        Cancel
      </button>
      <button
        type="submit"
        @click="onSubmitProfile"
        class="focus:outline-none ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-indigo-500"
      >
        Save
      </button>
    </div>

    <div class="bg-white px-4 py-5 text-left shadow sm:rounded-lg sm:p-6">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Personal Information
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            Use a permanent address where you can receive mail.
          </p>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
          <form action="#" method="POST">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6 sm:col-span-3">
                <label
                  for="first_name"
                  class="block text-sm font-medium text-gray-700"
                  >First name</label
                >
                <input
                  type="text"
                  name="first_name"
                  v-model="state.firstName"
                  id="first_name"
                  autocomplete="given-name"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div class="col-span-6 sm:col-span-3">
                <label
                  for="last_name"
                  class="block text-sm font-medium text-gray-700"
                  >Last name</label
                >
                <input
                  type="text"
                  name="last_name"
                  v-model="state.lastName"
                  id="last_name"
                  autocomplete="family-name"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div class="col-span-6 sm:col-span-4">
                <label
                  for="email_address"
                  class="block text-sm font-medium text-gray-700"
                  >Email address</label
                >
                <input
                  type="text"
                  name="email_address"
                  v-model="state.emailAddress"
                  id="email_address"
                  autocomplete="email"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div class="col-span-6 sm:col-span-3">
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700"
                  >Country / Region</label
                >
                <select
                  id="country"
                  name="country"
                  v-model="state.country"
                  autocomplete="country"
                  class="focus:outline-none mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option>United States</option>
                  <option>Canada</option>
                  <option>Mexico</option>
                </select>
              </div>

              <div class="col-span-6">
                <label
                  for="street_address"
                  class="block text-sm font-medium text-gray-700"
                  >Street address</label
                >
                <input
                  type="text"
                  name="street_address"
                  v-model="state.streetAddress"
                  id="street_address"
                  autocomplete="street-address"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                <label
                  for="city"
                  class="block text-sm font-medium text-gray-700"
                  >City</label
                >
                <input
                  type="text"
                  name="city"
                  v-model="state.city"
                  id="city"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  for="state"
                  class="block text-sm font-medium text-gray-700"
                  >State / Province</label
                >
                <input
                  type="text"
                  v-model="state.state"
                  name="state"
                  id="state"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  for="postal_code"
                  class="block text-sm font-medium text-gray-700"
                  >ZIP / Postal</label
                >
                <input
                  type="text"
                  name="postal_code"
                  v-model="state.postalCode"
                  id="postal_code"
                  autocomplete="postal-code"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="flex justify-end">
      <button
        type="button"
        class="focus:outline-none rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500"
      >
        Cancel
      </button>
      <button
        type="submit"
        class="focus:outline-none ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-indigo-500"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import API from "@/api";

export default {
  data() {
    return {
      fileList: [],
      uploadData: {},
      profileLoading: false,
    };
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.state.user.currentUser);

    const state = reactive({
      firstName: user.value.FirstName,
      lastName: user.value.LastName,
      emailAddress: user.value.Username,
      country: user.value.country,
      streetAddress: user.value.streetAddress,
      city: user.value.city,
      state: user.value.state,
      postalCode: user.value.postalCode,
    });

    let settings = {
      autoupload: false,
    };

    const handleAction = (file) => {
      console.log(file);
    };

    return {
      user,
      state,
      store,
      settings,
      handleAction,
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },

    handlePreview(file) {
      console.log("handlePreview", file);
    },

    handleSuccess(res, file) {
      console.log("handleSuccess file", file);
      console.log("handleSuccess res", res);
    },

    beforeUpload(file) {
      console.log("beforeUpload", file);

      return file;
    },

    onUploadChange(file) {
      this.uploadData = file;
      console.log("onUploadChange", file);
    },

    onSubmitProfile() {
      this.profileLoading = true;
      API.Admin.Users.uploadImage(this.uploadData.raw)
        .then((user) => {
          console.log({ user });
          this.store.dispatch("user/setUser", user.data.Item);
          this.$notify({
            title: "Success",
            message: "Upload successfully done.",
            type: "success",
          });
          this.fileList = [];
          this.uploadData = null;
          this.profileLoading = false;
        })
        .catch((error) => {
          this.$notify.error({
            title: "Error",
            message: error.message,
          });
          this.profileLoading = false;
        });
    },
  },
  watch: {
    fileList(value) {
      console.log("value", value);
    },
  },
};
</script>
